(function(cash) {
    "use strict";

    let Preloader = {
        show: () => {
            $('.bg-overlay').addClass('bg-overlay-show');
        },    
        hide: () => {
            $('.bg-overlay').removeClass('bg-overlay-show');
        }
    };

    window.Preloader = Preloader;
})(cash);