<template>
    <div
        class="col-12 col-xl-3 col-lg-4 col-md-6 mb-4"
        :data-content="popoverContent"
        :data-toggle="popoverContent ? 'popover' : null"
    >
        <div
            class="widget-small coloured-icon mb-0"
            :class="type"
        >
            <i
                class="icon"
                :class="icon"
            ></i>
            <div class="info">
                <h4>
                    {{ title }} <span v-if="titleValueEnabled">({{ value }})</span>
                </h4>
                <b v-if="withData">
                    <span v-if="data.length">
                        {{ data[0].label }} {{ data[0].value }}
                    </span>
                    <span v-if="data.length > 1">
                        / {{ data[1].label }} {{ data[1].value }}
                    </span>
                </b>
            </div>
        </div>
        <div v-if="footerEnabled" :class="'widget-small-footer bg-' + type">
            <a v-if="withLink" :href="link.url">
                {{ link.label }} 
                <i class="fa fa-arrow-circle-right"></i>
            </a>
            <span v-if="!withLink">{{ link.label }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                withLink: false,
                withData: false,
            }
        },
        props: {
            type: { type: String, default: 'info' },
            icon: { type: String, default: 'fa fa-question' },
            title: { type: String, default: 'title' },
            value: { type: Number | String, default: 'value' },
            footerEnabled: { type: Boolean, default: true },
            titleValueEnabled: { type: Boolean, default: true },
            popoverContent: { type: String },
            link: {
                type: Object,
                default: () => {
                    return {
                        label: 'link.label',
                        url: 'link.url',
                    }
                }
            },
            data: {
                type: Array, 
                default: () => {
                    return [
                        { label: 'data.0.label', value: 'data.0.value' },
                        { label: 'data.1.label', value: 'data.1.value' },
                    ];
                }
            },
        },
        mounted() {
            this.withLink = (this.link.url != undefined && this.link.url != 'link.url');
            this.withData = (this.data[0].label != 'data.0.label');
            config.loadPopover();
        }
    }
</script>
