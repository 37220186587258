<template>
    <div>
        <app-page-header
            icon="fa fa-check-circle-o"
            title="Exercícios"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Exercícios' },
            ]"
        ></app-page-header>

        <app-datatable
            entity="exercises"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :statusFilterIndex="4"
            :options="{
                ajax: {
                    url: '/web/exercises',
                },
                columns: columns,
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/exercises/create'
                },
                preview: {
                    allow: allows.show,
                    url: '*',
                    callback: onPreview
                },
                edit: {
                    allow: allows.edit,
                    url: '/exercises/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*'
                },
            }"
        ></app-datatable>

        <app-exercise-preview ref="exercisePreview"></app-exercise-preview>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Datatable from '../DataTableComponent';
    import ExercisePreview from './ExercisePreviewComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': Datatable,
            'app-exercise-preview': ExercisePreview,
        },
        props: {
            columns: Array,
            allows: Object,
        },
        methods: {
            onPreview(id) {
                this.$refs.exercisePreview.load(id);
            }
        }
    }
</script>