<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <i class="fa fa-globe"></i>
                        {{ title }}
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="onClose"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row content">
                        <div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
                        <div v-if="!loading" class="col-12">
                            <div class="row">
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Mundo</strong></p>
                                    <p>{{ level.world.name }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Nome</strong></p>
                                    <p>{{ level.name }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Direção Inicial</strong></p>
                                    <p>{{ level.direction_text }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Instrução</strong></p>
                                    <p>{{ level.instruction }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Blocos</strong></p>
                                    <p>{{ level.blocks }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Mapa</strong></p>
                                    <p>{{ level.map }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Quantidade de blocos</strong></p>
                                    <p>{{ level.blocks_amount }}</p>
                                </div>
                                <div class="col-12 col-xl-4 col-md-6">
                                    <p><strong>Status</strong></p>
                                    <p>{{ level.active_text }}</p>
                                </div>
                                <div class="col-12">
                                    <h5>Dicas</h5><hr/>
                                </div>
                                <div
                                    v-for="tip of level.tips"
                                    class="col-12 col-xl-4 my-1"
                                >
                                    <div class="card">
                                        <div class="card-body p-1">
                                            {{ tip.text }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                modal: HTMLElement,
                title: '',
                loading: true,
                level: {}
            }
        },
        props: {
            defaultTitle: { type: String, default: 'Fase' },
        },
        mounted() {
            this.title = this.defaultTitle;
        },
        methods: {
            open(id) {
                this.loading = true;
                this.modal = $(this.$refs.modal);
                this.title = 'Carregando...';
                this.modal.modal('show');

                axios.get(`/web/levels/${id}`)
                    .then(res => {
                        this.loading = false;
                        this.level = res.data;
                        this.title = `${this.defaultTitle}: ${this.level.id}`;
                    })
                    .catch(err => {});
            },
            onClose() {
                this.modal.modal('hide');
            },
        }
    }
</script>