<template>
    <div v-if="level">
        <app-page-header
			icon="fa fa-flag"
			:title="'Editar Fase: ' + id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/levels', title: 'Fases' },
				{ href: '', title: 'Editar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
						<form ref="form">
                            <div class="row">
                                <app-select
									name="world_id"
									label="Mundo"
									title="Mundo"
									:required="true"
									:options="worlds"
									:data-live-search="true"
									empty="false"
                                    :value="dataTemp.world_id"
								></app-select>
                                <app-input
                                    title="mínimo 2 caracters, máximo 100"
                                    name="name"
                                    label="Nome"
                                    placeholder="Nome"
                                    :maxLength="100"
                                    :minLength="2"
                                    :required="true"
                                    :value="level.name"
                                ></app-input>
                                <app-select
                                    containerClass="col-12 col-xl-2 col-md-6"
									name="direction"
									label="Direção Inicial"
									title="Direção Inicial"
									:required="true"
									:options="directions"
									:data-live-search="true"
									empty="false"
                                    :value="dataTemp.direction"
								></app-select>
                                <app-input
                                    containerClass="col-12 col-xl-2 col-md-6"
                                    title="mínimo 2 caracters, máximo 100"
                                    name="blocks_amount"
                                    label="Quantidade de blocos"
                                    :maxLength="100"
                                    :required="true"
                                    type="number"
                                    :value="level.blocks_amount"
                                ></app-input>
                                <app-textarea
                                    name="instruction"
                                    label="Instrução"
                                    placeholder="Instrução"
                                    :maxLength="255"
                                    :required="true"
                                    :counter="true"
                                    resize="none"
                                    :value="level.instruction"
                                ></app-textarea>
                                <app-textarea
                                    name="blocks"
                                    label="Blocos"
                                    placeholder="Blocos"
                                    :maxLength="2000"
                                    :required="true"
                                    :counter="true"
                                    resize="none"
                                    :value="level.blocks"
                                ></app-textarea>
                                <app-textarea
                                    name="map"
                                    label="Mapa"
                                    placeholder="Mapa"
                                    :maxLength="255"
                                    :required="true"
                                    :counter="true"
                                    resize="none"
                                    :value="level.map"
                                ></app-textarea>
                                <app-toggle
									label="Status"
									name="active"
									:value="level.active"
								></app-toggle>
                            </div>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="card">
                                        <div
                                            :class="[
                                                'card-header d-flex justify-content-between',
                                                'align-items-center px-2'
                                            ]"
                                        >
                                            <div class="font-weight-bold">Dicas</div>
                                            <div>
                                                <a
                                                    :class="[
                                                        'btn btn-danger btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ]"
                                                    @click="onDelete"
                                                    title="Excluir Dica"
                                                >
                                                    <span class="fa fa-trash text-white"></span>
                                                </a>
                                                <a
                                                    :class="[
                                                        'btn btn-info btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ]"
                                                    @click="onAdd"
                                                    title="Add Dica"
                                                >
                                                    <span class="fa fa-plus text-white"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div
                                                    v-for="(tip, index) in tips"
                                                    class="col-12 col-xl-6"
                                                >
                                                    <input
                                                        v-if="tip.id"
                                                        :name="'tips[' + index + '][id]'"
                                                        type="hidden"
                                                        :value="tip.id"
                                                    >
                                                    <app-textarea
                                                        containerClass=""
                                                        :name="'tips[' + index + '][text]'"
                                                        :label="'Dica ' + index"
                                                        placeholder="Dica"
                                                        :maxLength="300"
                                                        :required="true"
                                                        :counter="true"
                                                        resize="none"
                                                        :value="tip.text"
                                                    ></app-textarea>
                                                </div>
                                            </div>
                                            <input
                                                type="hidden"
                                                name="tipIDs"
                                                :value="tipRemovalList"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Select from '../forms/SelectComponent';
    import Textarea from '../forms/TextareaComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import Toggle from '../forms/ToggleComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-select': Select,
            'app-textarea': Textarea,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
            'app-toggle': Toggle,
        },
        data() {
            return {
                level: null,
                tips: [],
                worlds: [],
                directions: [],
                tipRemovalList: '',
                dataTemp: {
                    world_id: '',
                    direction: '',
                }
            }
        },
        props: {
            id: String,
            backUrl: String,
        },
        mounted() {
            this.load(this.id);
        },
        methods: {
            load(id) {
                axios.get(`/web/levels/${id}`)
                    .then(res => {
                        this.level = res.data;
                        this.tips = this.level.tips;

                        this.loadWorlds();
                        this.loadDirections();
                    })
                    .catch(err => {});
            },
            loadWorlds() {
                axios.get('/web/worlds/list?toTags=true')
                    .then(res => {
                        this.worlds = res.data;
                        this.dataTemp.world_id = this.level.world_id;
                    })
                    .catch(err => {});
            },
            loadDirections() {
                axios.get('/web/levels/directions?toTags=true')
                    .then(res => {
                        this.directions = res.data;
                        this.dataTemp.direction = this.level.direction;
                    })
                    .catch(err => {});
            },
            onAdd() {
                this.tips.push({ text: '' });
            },
            onDelete() {
                if (this.tips.length) {
                    let id = this.tips[this.tips.length - 1].id;
                    this.tipRemovalList = this.tipRemovalList + ',' + id;
                }

                this.tips.pop();
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');

                    axios.post(`/web/levels/${this.level.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Atualizado com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/levels';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                        });
                }
            }
        }
    }
</script>