var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-user","title":"Professores","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Professores' }
        ]}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"entity":"teachers","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"options":{
            ajax: {
                url: '/web/teachers'
            },
            columns: _vm.columns,
            searchCols: _vm.initFilter
        },"actions":{
            show: {
                allow: _vm.allows.show,
                url: '*',
                callback: _vm.onShow
            },
        },"filters":_vm.filters}}),_vm._v(" "),_c('app-teacher-show',{ref:"teacherShow"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }