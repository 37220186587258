<template>
    <div class="col-md-6">
        <div class="tile" :title="legend">
            <h3 class="tile-title">{{ title }}</h3>
            <div class="embed-responsive embed-responsive-16by9">
                <canvas class="embed-responsive-item" ref="graph"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                graph: null,
            }
        },
        props: {
            title: { type: String },
            legend: { type: String },
            data: { type: Object },
        },
        watch: {
            data: function (newVal, oldVal) {
                this.show();
            }
        },
        methods: {
            show() {
                if (this.graph) { this.graph.destroy(); }

                this.graph = new Chart(this.$refs.graph, {
                    type: 'pie',
                    data: {
                        labels: this.data.labels,
                        datasets: [{
                            data: this.data.data,
                            backgroundColor: [
                                'rgba(90, 173, 0, 0.5)',
                                'rgba(255, 255, 0, 0.5)',
                                'rgba(255, 50, 0, 0.5)',
                            ],
                            borderColor: 'rgba(0, 0, 0, 0.2)',
                            borderWidth: 1,
                        }]
                    },
                    options: {
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    return data.labels[tooltipItem.index] + ': '
                                        + data.datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index] + '%';
                                }
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    }
                });
            }
        }
    }
</script>