<template>
    <section class="login-content">
        <div class="logo">
            <h1>
                <a href="/" :title="title">
                    <img src="/assets/img/logo.png" :alt="title">
                </a>
            </h1>
        </div>
        <div class="login-box">
            <form class="login-form" method="POST" ref="form" autocomplete="off">
                <h3 class="login-head font-weight-normal">Redefinir senha</h3>
                <input
                    type="email"
                    name="email"
                    class="form-control"
                    minlength="2"
                    maxlength="65"
                    placeholder="E-mail"
                    readonly
                    :value="email"
                >

                <input
                    type="password"
                    name="password"
                    class="form-control"
                    autofocus
                    minlength="6"
                    maxlength="12"
                    placeholder="Senha"
                    required
                >

                <input
                    type="password"
                    name="password_confirmation"
                    class="form-control"
                    minlength="6"
                    maxlength="12"
                    placeholder="Confirmar Senha"
                    required
                    @keyup="onSubmit"
                >

                <button
                    type="button"
                    class="btn btn-info btn-block"
                    @click="onSubmit"
                    :disabled="logging"
                >
            		<span
                        v-if="logging"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span v-if="!logging">Redefinir senha</span>
                </button>
            </form>
        </div>
        <h6 class="version text-muted m-0 h6">v.{{ appVersion }}</h6>
    </section>
</template>

<script>
export default {
    data() {
        return {
            logging: false
        }
    },
    props: {
        title: String,
        appVersion: String,
        router: String,
        token: String,
        email: String
    },
    methods: {
        onSubmit(e = null) {
            if (e instanceof Event && e.keyCode != 13 && e.type != 'click') return;

            this.logging = true;
            let data = new FormData(this.$refs.form);
            data.append('token', this.token);

            let validate = this.validateForm(this.$refs.form);

            if (validate != '') {
                this.logging = false;
                Flash('error', validate);
            } else {
                axios.post('/web/password/reset', data)
                    .then(res => {
                        this.logging = false;

                        Swal.fire({
                            text: res.data.message,
                            type: 'success'
                        })
                            .then(() => {
                                window.location.href = '/login';
                            });
                    })
                    .catch(err => {
                        Flash('error', 'Erro ao redefinir a senha!');
                        this.logging = false;
                    });
            }
        },
        validate(form) {
            cash(form).find('input,select').removeClass('is-invalid');
            cash(form).find('input,select').removeClass('is-valid');

            let result = this.validateForm(form.elements);

            return result;
        }
    }
}
</script>
