<template>
	<div
		:id="containerId"
		:class="containerClass"
	>
	<div class="form-group" :class="{ 'required' : required }">
		<label v-if="label" :for="name">
			{{ label }}
			<a
				v-if="help"
				:id="id"
				tabindex="0"
				class="btn btn-outline-info btn-circle btn-circle-xm mb-1"
				data-toggle="popover"
				data-trigger="focus"
				role="button"
				:data-content="help.text"
			>
			<i class="fa fa-question"></i>
		</a>
	</label>
	<textarea
		ref="textarea"
		:name="name"
		:class="[
			{ 'is-invalid' : error },
			{ 'editor' : withEditor },
			className,
		]"
		:minlength="minLength"
		:maxlength="maxLength"
		:title="title"
		:required="required"
		:placeholder="placeholder"
		:rows="rows"
		v-model="input"
		@change="onChange"
		:style="'resize: ' + resize"
		@input="onInputArea"
	></textarea>
	
	<div v-if="error" class="invalid-feedback">
		{{ error }}
	</div>
</div>
</div>
</template>

<script>
export default {
	data() {
		return {
			input: '',
			editor: null,
		}
	},
	props: {
		id: String,
		placeholder: String,
		className: {type: String, default: 'form-control'},
		label: String,
		name: String,
		value: String,
		minLength: Number,
		maxLength: Number,
		title: String,
		required: Boolean,
		containerId: String,
		containerClass: {type: String, default: 'col-12 col-xl-3 col-md-6'},
		help: Object,
		error: String,
		rows: {type: Number, default: 5},
		resize: {type: String, default: 'both'},
		withEditor: Boolean,
		callback: Function,
		counter: { type: Boolean, default: false },
	},
	mounted() {
		this.input = this.value || '';
		
		if (this.withEditor) {
			this.editor = $(this.$refs.textarea);
			
			this.editor.Editor({
				insert_img: false,
				print: false,
				togglescreen: false,
				block_quote: false,
				undo: false,
				redo: false
			});
			
			this.editor.data('editor').on('keyup', this.onInput);
			this.setValue(this.input);
		}
	},
	watch: {
		value(newValue, oldValue) {
			this.input = newValue;
		}
	},
	methods: {
		onInputArea(e) {
			if (!this.counter) return;

			let textarea = $(e.target);
			const LIMIT = textarea.attr('maxlength');
			let label = textarea.parent().find('label');
			let labelIndex = label.html().indexOf('(');
			let text = '';

			if (labelIndex != -1) {
				text = label.html().substr(0, labelIndex);
			} else {
				text = label.html();
			}

			let amount = textarea.val().length;
			label.html(`${text} (${amount}/${LIMIT})`);
		},
		getValue() {
			if (this.withEditor) {
				return this.editor.Editor('getText');
			}
		},
		setValue(value) {
			if (this.withEditor) {
				this.editor.Editor('setText', value);
			}
		},
		onInput(e) {
			this.editor.val(this.getValue());
		},
		onChange(e) {
			if (this.callback) {
				this.callback(this.input, e);
			}
		}
	}
}
</script>
