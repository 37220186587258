<template>
    <div class="col-6 col-md-3">
        <div class="card shadow-sm">
            <div class="card-header font-weight-bold">{{ exercise.title }}</div>
            <div class="card-body">
                <div ref="bar" class="progress-circle progress mx-auto" title="Desempenho">
                    <span class="progress-left">
                        <span :class="'progress-bar border-' + color"></span>
                    </span>
                    <span class="progress-right">
                        <span :class="'progress-bar border-' + color"></span>
                    </span>
                    <div
                        :class="[
                            'progress-value w-100 h-100 rounded-circle d-flex',
                            'align-items-center justify-content-center'
                        ]"
                    >
                        <div class="h2 font-weight-bold">
                            {{ exercise.lastPerformance }}<sup class="small">%</sup>
                        </div>
                    </div>
                </div>

                <div class="row text-center mt-4">
                    <div class="col-6 border-right">
                        <div class="h4 font-weight-bold mb-0">
                            {{ exercise.progress }}%
                        </div>
                        <span class="small text-gray">Progresso</span>
                    </div>
                    <div class="col-6">
                        <div class="h4 font-weight-bold mb-0">
                            {{ (exercise.prevPerformance)
                                ? exercise.prevPerformance + '%'
                                : '--'
                            }}
                        </div>
                        <span class="small text-gray">Desemp. anterior</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                bar: HTMLElement,
                color: '',
            }
        },
        props: {
            exercise: null
        },
        mounted() {
            this.bar = $(this.$refs.bar);
            this.setValue();
            this.color = config.getPerformanceColor(this.exercise.lastPerformance);
        },
        methods: {
            setValue() {
                let left = this.bar.find('.progress-left .progress-bar');
                let right = this.bar.find('.progress-right .progress-bar');
                let value = this.exercise.lastPerformance;

                if (value > 0) {
                    if (value <= 50) {
                        right.css('transform', `rotate(${this.pctToDegrees(value)}deg)`);
                    } else {
                        right.css('transform', 'rotate(180deg)');
                        left.css('transform', `rotate(${this.pctToDegrees(value - 50)}deg)`);
                    }
                }
            },
            pctToDegrees(pct) {
                return pct / 100 * 360;
            }
        }
    }
</script>
