var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-dashboard","title":"Painel","breadcrumbs":[
            { href: '', title: 'Painel' } ]}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('app-jumbotron',{attrs:{"name":'prof. ' + _vm.userName}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tile py-0 pl-0"},[_c('div',{staticClass:"tile-body row m-0"},[_c('div',{class:[
                            'widget-small light mb-0 coloured-icon',
                            'align-self-sm-start col-12 p-0'
                        ]},[_c('i',{staticClass:"icon fa fa-filter fa-3x py-4 py-sm-3"}),_vm._v(" "),_c('div',{staticClass:"row d-flex align-items-center w-100 ml-2"},[_c('app-select',{attrs:{"formGroupClass":"form-group mb-0","containerClass":"col-12 col-xl-2 col-sm-4","className":"custom-select form-control mb-0","name":"year","empty":"false","options":_vm.years,"value":_vm.currentYear,"callback":_vm.onSelectYear}})],1)])])])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('app-widget',{attrs:{"type":"info","icon":"fa fa-user","title":"Alunos","footerEnabled":false,"titleValueEnabled":false,"data":[{ label: '', value: _vm.players }],"popoverContent":"Alunos da(s) sua(s) sala(s) que acessam o UCode"}}),_vm._v(" "),_c('app-widget',{attrs:{"type":"primary","icon":"fa fa-gamepad","title":"Fases Concluídas","footerEnabled":false,"titleValueEnabled":false,"data":[
                { label: '', value: _vm.levels.completed },
                { label: '', value: _vm.levels.amount } ],"popoverContent":"Total de Fases Concluídas Distribuidas Entre os Alunos"}}),_vm._v(" "),_c('app-widget',{attrs:{"type":"danger","icon":"fa fa-apple","title":"Maçãs Capturadas","footerEnabled":false,"titleValueEnabled":false,"data":[
                { label: '', value: _vm.apples.catched },
                { label: '', value: _vm.apples.amount } ],"popoverContent":"Total de Maçãs Capturadas Distribuidas Entre os Alunos"}}),_vm._v(" "),_c('app-widget',{attrs:{"type":"info","icon":"fa fa-sign-in","title":"Acessos","footerEnabled":false,"titleValueEnabled":false,"data":[{ label: '', value: _vm.accesses }],"popoverContent":"Total de Acessos dos Alunos"}}),_vm._v(" "),_c('app-widget',{attrs:{"type":"warning","icon":"fa fa-check-circle-o","title":"Exercícios","footerEnabled":false,"titleValueEnabled":false,"data":[
                { label: '', value: _vm.exercises.completed },
                { label: '', value: _vm.exercises.amount } ],"popoverContent":"Exercícios Concluídos Entre os Alunos"}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('app-pizza-graph',{attrs:{"title":"Desempenho das Fases","legend":"Desempenho dos Alunos por Mundos","data":this.performance}}),_vm._v(" "),_c('app-bar-graph',{attrs:{"title":"Distribuição de desempenho das Fases","legend":"Distribuição de Desempenho dos Alunos","data":this.distribution}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }