<template>
    <div>
        <app-page-header
            icon="fa fa-flag"
            title="Fases"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Fases' },
            ]"
        ></app-page-header>

        <app-datatable
            entity="levels"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :statusFilterIndex="6"
            :options="{
                ajax: {
                    url: '/web/levels',
                },
                columns: columns,
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/levels/create'
                },
                show: {
                    allow: allows.show,
                    url: '*',
                    callback: onShow
                },
                edit: {
                    allow: allows.edit,
                    url: '/levels/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*'
                },
            }"
            :filters="filters"
        ></app-datatable>

        <app-level-show ref="levelShow"></app-level-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Datatable from '../DataTableComponent';
    import LevelShow from './LevelShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': Datatable,
            'app-level-show': LevelShow,
        },
        data() {
            return {
                filters: [
                    {
                        name: 'world_id',
                        type: 'select',
                        label: 'Mundo',
                        index: 4,
                        options: [],
                        liveSearch: true,
                    }
                ]
            }
        },
        props: {
            columns: Array,
            allows: Object,
        },
        mounted() {
            this.loadWorlds();
        },
        methods: {
            onShow(id) {
                this.$refs.levelShow.open(id);
            },
            loadWorlds() {
                axios.get('/web/worlds/list?toTags=true')
                    .then(res => {
                        let filter = this.filters.filter(f => {
                            return f.name == 'world_id';
                        })[0];
                        filter.options = res.data;

                        if (res.data.length == 1) {
                            filter.value = res.data[0].value;
                        }
                    })
                    .catch(err => {});
            }
        }
    }
</script>