<template>
    <aside class="app-sidebar">
		<div class="app-sidebar__user">
			<img :src="user.image" class="app-sidebar__user-avatar">
			<div class="col-8 p-0">
				<p class="app-sidebar__user-name">{{ userName }}</p>
				<p class="app-sidebar__user-designation text-truncate">
					{{ user.profile.title }}
				</p>
			</div>
		</div>
		<app-side-menu></app-side-menu>
	</aside>
</template>

<script>
    import SideMenu from './SideMenuComponent';

    export default {
        components: {
            'app-side-menu': SideMenu
        },
		data() {
			return {
				userName: '',
			}
		},
        props: {
            user: Object,
        },
		mounted() {
			this.userName = this.getName();
		},
		methods: {
			getName() {
				switch (this.user.profile.code) {
					case 'manager': return this.user.manager.name;
					case 'teacher': return this.user.teacher.name;
				}

				return '';
			}
		}
    }
</script>