<template>
    <div>
        <app-page-header
            icon="fa fa-globe"
            title="Mundos"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Mundos' },
            ]"
        ></app-page-header>

        <app-datatable
            entity="worlds"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :statusFilterIndex="5"
            :options="{
                ajax: {
                    url: '/web/worlds',
                },
                columns: columns,
            }"
            :actions="{
                create: {
                    allow: allows.create,
                    url: '/worlds/create'
                },
                show: {
                    allow: allows.show,
                    url: '*',
                    callback: onShow
                },
                level: {
                    allow: allows.level,
                    url: '/levels/world_id=*',
                },
                edit: {
                    allow: allows.edit,
                    url: '/worlds/*/edit',
                },
                delete: {
                    allow: allows.delete,
                    url: '*'
                },
            }"
        ></app-datatable>

        <app-world-show ref="worldShow"></app-world-show>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Datatable from '../DataTableComponent';
    import WorldShow from './WorldShowComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-datatable': Datatable,
            'app-world-show': WorldShow,
        },
        props: {
            columns: Array,
            allows: Object,
        },
        methods: {
            onShow(id) {
                this.$refs.worldShow.open(id);
            }
        }
    }
</script>