<template>
    <section class="material-half-bg">
	    <div class="cover"></div>
	</section>
</template>

<script>
	export default {
		
	}
</script>