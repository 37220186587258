<template>
    <header class="app-header">
        <a href="/" class="app-header__logo p-0" :title="title">
			<img src="/assets/img/logoPanel.png">
		</a>
        <a
			class="app-sidebar__toggle"
			href="javascript:"
			data-toggle="sidebar"
			aria-label="Hide Sidebar"
			@click="onToggleSideBar"
		></a>
        <ul class="app-nav">
            <li ref="search" class="app-search dropdown">
                <input
					class="app-search__input"
					type="search"
					placeholder="Busca"
					v-model="search"
					v-on:keyup.enter="onSearch"
					data-toggle="dropdown"
				>
                <button class="app-search__button" @click="onSearch">
					<i class="fa fa-search"></i>
				</button>
                <ul	
					ref="searchResult"
					class="app-notification dropdown-menu dropdown-menu-right"
				>
                    <div class="app-notification__content search">
                        <li
							v-if="noResult"
							class="app-notification__title bg-light border-bottom text-center text-muted"
						>
							Nenhum Resultado!
						</li>
                        <div v-for="result of resultData">
                            <li
								v-if="result.data.length"
								class="app-notification__title bg-light border-bottom text-left text-muted"
							>
								{{ result.name }}
							</li>
                            <li v-for="item of result.data" class="bg-light">
								<a
									class="app-notification__item bg-light p-2"
									:href="'/' + result.code + '?id=' + item.id"
								>
									<span class="app-notification__icon">
										<span class="fa-stack fa-lg">
											<i class="fa fa-circle fa-stack-2x text-secondary"></i>
											<i
												class="fa fa-stack-1x fa-inverse"
												:class="'fa-' + result.icon"
											></i>
										</span>
									</span>
									<div class="d-flex align-items-center">
										<p class="app-notification__message">
											{{ item.name ? item.name : item.title }}
										</p>
										<!-- <p class="app-notification__meta">2 min</p> -->
									</div>
								</a>
							</li>
                        </div>
                    </div>
                </ul>
            </li>

            <app-notifications></app-notifications>
            <app-header-profile :user-id="userId"></app-header-profile>
        </ul>
    </header>
</template>

<script>
    import Notification from './NotificationComponent';
    import HeaderProfile from './HeaderProfileComponent';

    export default {
        components: {
            'app-notifications': Notification,
            'app-header-profile': HeaderProfile,
        },
        data() {
            return {
                search: '',
				resultData: [],
				noResult: false
            }
        },
        props: {
            title: String,
            userId: Number,
        },
        mounted() {
            $(this.$refs.search).on('show.bs.dropdown', this.onOpen);
        },
        methods: {
            onToggleSideBar() {
				if (cash('.sidenav-toggled').length) {
					localStorage.setItem('menuState', 'show');
				} else {
					localStorage.setItem('menuState', 'hide');
				}
			},
            onOpen() {
				this.resultData = [];
			},
            onSearch() {
				if (this.search.length > 2) {
					this.resultData = [];

					axios.get('/web/search', { params: { search: this.search }})
						.then(async res => {
							this.resultData = res.data;
							this.noResult = true;
							
							for (let r of this.resultData) {
								if (r.data.length) {
									this.noResult = false;
									break;
								}
							}
						})
						.catch(err => {});
				} else {
					Flash('warning', 'digite pelo menos 3 caracteres!');
				}
			}
        }
    }
</script>