<template>
    <div class="col-6 col-xl-2 col-lg-3 col-md-4">
        <div :class="'card mb-3 shadow rounded-lg border-' + level.color">
            <div
                :class="[
                    'card-header p-2 d-flex justify-content-center',
                    'rounded-top-lg alert-{{ level.color }}'
                ]"
            >
                <img
                    v-for="i of [1,2,3]"
                    :class="[
                        'star',
                        (i <= level.stars) ? '' : 'star-lock'
                    ]"
                    src="assets/img/star.png"
                >
            </div>
            <div class="card-body p-2">
                <h6 class="card-title">{{ level.name }}</h6>
                <div class="d-flex justify-content-between">
                    <p class="card-text">Jogou</p>
                    <p class="card-text">{{ level.times }}x</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            level: null
        }
    }
</script>
