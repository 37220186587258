window._ = require('lodash');

// Load plugins
import cash from 'cash-dom';
import axios from 'axios';
import moment from 'moment';

// Set plugins globally
window.cash = cash;
window.axios = axios;
window.moment = moment;

moment.locale('pt-br');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// CSRF token
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.interceptors.request.use(config => {
        if (!(config.url.indexOf('https://viacep.com.br') != -1)) {
            config.headers['X-CSRF-TOKEN'] = token.content;
        }

        return config;
    });
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
