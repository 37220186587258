/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import './flash';
import './utils/help';
import './utils/preloader';
import './config';
import './mobile-check';

import Vue from 'vue';
import Validator from './plugins/validator';
import AddScriptPlugin from './plugins/addScripts';
import DatatablesButtons from './plugins/datatablesButtons';
import VueMask from 'v-mask';

window.DEBUG = false;

 /**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import Cover from './components/CoverComponent';
import Header from './components/HeaderComponent';
import Side from './components/SideComponent';
import Footer from './components/FooterComponent';

/** PAGES */
import Login from './components/LoginComponent';
import PasswordEmail from './components/passwords/EmailComponent';
import ResetComponent from './components/passwords/ResetComponent';

import Menu from './components/menus/MenuComponent';
import MenuCreate from './components/menus/MenuCreateComponent';
import MenuEdit from './components/menus/MenuEditComponent';
import Resource from './components/resources/ResourceComponent';
import ResourceCreate from './components/resources/ResourceCreateComponent';
import ResourceEdit from './components/resources/ResourceEditComponent';

import Profile from './components/profiles/ProfileComponent';
import ProfileCreate from './components/profiles/ProfileCreateComponent';
import ProfileEdit from './components/profiles/ProfileEditComponent';

import DashboardManager from './components/dashboards/DashboardManager';
import DashboardTeacher from './components/dashboards/DashboardTeacher';

import UserEdit from './components/users/UserEditComponent';

import Teacher from './components/teachers/TeacherComponent';
import Player from './components/players/PlayerComponent';

import World from './components/worlds/WorldComponent';
import WorldCreate from './components/worlds/WorldCreateComponent';
import WorldEdit from './components/worlds/WorldEditComponent';

import Level from './components/levels/LevelComponent';
import LevelCreate from './components/levels/LevelCreateComponent';
import LevelEdit from './components/levels/LevelEditComponent';

import Character from './components/characters/CharacterComponent';
import CharacterCreate from './components/characters/CharacterCreateComponent';
import CharacterEdit from './components/characters/CharacterEditComponent';

import Exercise from './components/exercises/ExerciseComponent';
import ExerciseCreate from './components/exercises/ExerciseCreateComponent';
import ExerciseEdit from './components/exercises/ExerciseEditComponent';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.use(Validator);
Vue.use(AddScriptPlugin);
Vue.use(DatatablesButtons);
Vue.use(VueMask);

const app = new Vue({
    el: '#app',
    components: {
        'app-cover': Cover,
        'app-header': Header,
        'app-side': Side,
        'app-footer': Footer,

        'app-login': Login,
        'app-password-email': PasswordEmail,
        'app-password-reset': ResetComponent,

        'app-menu': Menu,
        'app-menu-create': MenuCreate,
        'app-menu-edit': MenuEdit,
        'app-resource': Resource,
        'app-resource-create': ResourceCreate,
        'app-resource-edit': ResourceEdit,

        'app-profile': Profile,
        'app-profile-create': ProfileCreate,
    	'app-profile-edit': ProfileEdit,

        'app-dashboard-manager': DashboardManager,
        'app-dashboard-teacher': DashboardTeacher,

        'app-user-edit': UserEdit,

        'app-teacher': Teacher,
        'app-player': Player,

        'app-world': World,
        'app-world-create': WorldCreate,
        'app-world-edit': WorldEdit,

        'app-level': Level,
        'app-level-create': LevelCreate,
        'app-level-edit': LevelEdit,

        'app-character': Character,
        'app-character-create': CharacterCreate,
        'app-character-edit': CharacterEdit,

        'app-exercise': Exercise,
        'app-exercise-create': ExerciseCreate,
        'app-exercise-edit': ExerciseEdit,
    }
});
