<template>
    <div>
        <app-page-header
			icon="fa fa-check-circle-o"
			title="Cadastrar Exercício"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/exercises', title: 'Exercícios' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
						<form ref="form">
                            <div class="row">
                                <app-select
									name="skill_id"
									label="Habilidade"
									title="Habilidade"
									:required="true"
									:options="skills"
									:data-live-search="true"
									empty="false"
								></app-select>
                                <app-input
                                    title="mínimo 2 caracters, máximo 20"
                                    name="title"
                                    label="Título"
                                    placeholder="Título"
                                    :maxLength="20"
                                    :minLength="2"
                                    :required="true"
                                ></app-input>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Select from '../forms/SelectComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-select': Select,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
        },
        data() {
            return {
                skills: []
            }
        },
        props: {
            backUrl: String,
        },
        mounted() {
            this.loadSkills();
        },
        methods: {
            loadSkills() {
                axios.get('/web/skills/list?toTags=true')
                    .then(res => {
                        this.skills = res.data;
                    })
                    .catch(err => {});
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    axios.post(`/web/exercises`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/exercises';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
            }
        }
    }
</script>
