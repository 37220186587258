<template>
    <div>
        <app-page-header
            icon="fa fa-dashboard"
            title="Painel"
            :breadcrumbs="[
                { href: '', title: 'Painel' },
            ]"
        ></app-page-header>

        <div class="row">
            <app-jumbotron :name="'prof. ' + userName"></app-jumbotron>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="tile py-0 pl-0">
                    <div class="tile-body row m-0">
                        <div
                            :class="[
                                'widget-small light mb-0 coloured-icon',
                                'align-self-sm-start col-12 p-0'
                            ]"
                        >
                            <i class="icon fa fa-filter fa-3x py-4 py-sm-3"></i>
                            <div class="row d-flex align-items-center w-100 ml-2">
                                <app-select
                                    formGroupClass="form-group mb-0"
                                    containerClass="col-12 col-xl-2 col-sm-4"
                                    className="custom-select form-control mb-0"
                                    name="year"
                                    empty="false"
                                    :options="years"
                                    :value="currentYear"
                                    :callback="onSelectYear"
                                ></app-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <app-widget
                type="info"
                icon="fa fa-user"
                title="Alunos"
                :footerEnabled="false"
                :titleValueEnabled="false"
                :data="[{ label: '', value: players }]"
                popoverContent="Alunos da(s) sua(s) sala(s) que acessam o UCode"
            ></app-widget>
            <app-widget
                type="primary"
                icon="fa fa-gamepad"
                title="Fases Concluídas"
                :footerEnabled="false"
                :titleValueEnabled="false"
                :data="[
                    { label: '', value: levels.completed },
                    { label: '', value: levels.amount },
                ]"
                popoverContent="Total de Fases Concluídas Distribuidas Entre os Alunos"
            ></app-widget>
            <app-widget
                type="danger"
                icon="fa fa-apple"
                title="Maçãs Capturadas"
                :footerEnabled="false"
                :titleValueEnabled="false"
                :data="[
                    { label: '', value: apples.catched },
                    { label: '', value: apples.amount },
                ]"
                popoverContent="Total de Maçãs Capturadas Distribuidas Entre os Alunos"
            ></app-widget>
            <app-widget
                type="info"
                icon="fa fa-sign-in"
                title="Acessos"
                :footerEnabled="false"
                :titleValueEnabled="false"
                :data="[{ label: '', value: accesses }]"
                popoverContent="Total de Acessos dos Alunos"
            ></app-widget>
            <app-widget
                type="warning"
                icon="fa fa-check-circle-o"
                title="Exercícios"
                :footerEnabled="false"
                :titleValueEnabled="false"
                :data="[
                    { label: '', value: exercises.completed },
                    { label: '', value: exercises.amount },
                ]"
                popoverContent="Exercícios Concluídos Entre os Alunos"
            ></app-widget>
        </div>

        <div class="row">
            <app-pizza-graph
                title="Desempenho das Fases"
                legend="Desempenho dos Alunos por Mundos"
                :data="this.performance"
            ></app-pizza-graph>
            <app-bar-graph
                title="Distribuição de desempenho das Fases"
                legend="Distribuição de Desempenho dos Alunos"
                :data="this.distribution"
            ></app-bar-graph>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Jumbotron from '../JumbotronComponent';
    import Select from '../forms/SelectComponent';
    import Widget from './WidgetComponent';
    import PizzaGraph from '../graphs/PizzaGraphComponent';
    import BarGraph from '../graphs/BarGraphComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-jumbotron': Jumbotron,
            'app-select': Select,
            'app-widget': Widget,
            'app-pizza-graph': PizzaGraph,
            'app-bar-graph': BarGraph,
		},
        data() {
            return {
                userName: '',
                years: [],
                currentYear: '',
                players: 0,
                levels: { completed: 0, amount: 0 },
                apples: { catched: 0, amount: 0 },
                accesses: 0,
                distribution: null,
                performance: null,
                exercises: { completed: 0, amount: 0 },
            }
        },
        props: {
            user: Object,
        },
        mounted() {
            this.userName = this.getName();
            this.loadYears();
        },
        methods: {
            getName() {
                switch (this.user.profile.code) {
                    case 'manager': return this.user.manager.name;
                    case 'teacher': return this.user.teacher.name;
                }

                return '';
            },
            loadYears() {
                axios.get(`/web/years/list`)
                    .then(res => {
                        this.years = res.data;
                        this.currentYear = this.years[0].value;
                        this.loadData(this.currentYear);
                    })
                    .catch(err => {});
            },
            onSelectYear(year) {
                this.loadData(year);
            },
            loadData(year) {
                let data = { year: year };

                axios.get(`/web/dashboard/teacher`, { params: data })
                    .then(res => {
                        this.players = res.data.players;
                        this.levels = res.data.levels;
                        this.apples = res.data.apples;
                        this.accesses = res.data.accesses;
                        this.distribution = res.data.distribution;
                        this.performance = res.data.performance;
                        this.exercises = res.data.exercises;
                    })
                    .catch(err => {});
            }
        }
    }
</script>
