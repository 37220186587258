var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-check-circle-o","title":"Exercícios","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Exercícios' } ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"exercises","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"statusFilterIndex":4,"options":{
            ajax: {
                url: '/web/exercises',
            },
            columns: _vm.columns,
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/exercises/create'
            },
            preview: {
                allow: _vm.allows.show,
                url: '*',
                callback: _vm.onPreview
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/exercises/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*'
            },
        }}}),_vm._v(" "),_c('app-exercise-preview',{ref:"exercisePreview"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }