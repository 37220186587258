<template>
    <li class="dropdown">
        <a
			id="bell-notifications"
			class="app-nav__item"
			href="javascript:"
			data-toggle="dropdown"
			aria-label="Show notifications"
			title="Notificações"
		>
			<i class="fa fa-bell-o fa-lg"></i>
			<!-- <span id="notification-bell" class="badge badge-pill badge-warning">0</span> -->
		</a>
        <ul class="app-notification dropdown-menu dropdown-menu-right">
			<li class="app-notification__title bg-light border-bottom font-weight-bold text-left">
				Notificações (0)
			</li>
			<div class="app-notification__content">
				<!-- <li class="bg-light">
                    <a class="app-notification__item bg-light" href="javascript:">
                        <span class="app-notification__icon">
                            <span class="fa-stack fa-lg">
                                <i class="fa fa-circle fa-stack-2x text-primary"></i>
                                <i class="fa fa-envelope fa-stack-1x fa-inverse"></i>
                            </span>
                        </span>
                        <div>
                            <p class="app-notification__message">Lisa enviou um email</p>
                            <p class="app-notification__meta">2 min</p>
                        </div>
                    </a>
                </li> -->
			</div>
            <!-- <li class="app-notification__footer bg-light">
                <a class="bg-light" href="javascript:">Ver todas.</a>
            </li> -->
		</ul>
    </li>
</template>

<script>
    export default {

    }
</script>