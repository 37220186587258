var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.level)?_c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-flag","title":'Editar Fase: ' + _vm.id,"breadcrumbs":[
				{ href: '/', title: 'Painel' },
				{ href: '/levels', title: 'Fases' },
				{ href: '', title: 'Editar' } ]}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tite-body"},[_c('form',{ref:"form"},[_c('div',{staticClass:"row"},[_c('app-select',{attrs:{"name":"world_id","label":"Mundo","title":"Mundo","required":true,"options":_vm.worlds,"data-live-search":true,"empty":"false","value":_vm.dataTemp.world_id}}),_vm._v(" "),_c('app-input',{attrs:{"title":"mínimo 2 caracters, máximo 100","name":"name","label":"Nome","placeholder":"Nome","maxLength":100,"minLength":2,"required":true,"value":_vm.level.name}}),_vm._v(" "),_c('app-select',{attrs:{"containerClass":"col-12 col-xl-2 col-md-6","name":"direction","label":"Direção Inicial","title":"Direção Inicial","required":true,"options":_vm.directions,"data-live-search":true,"empty":"false","value":_vm.dataTemp.direction}}),_vm._v(" "),_c('app-input',{attrs:{"containerClass":"col-12 col-xl-2 col-md-6","title":"mínimo 2 caracters, máximo 100","name":"blocks_amount","label":"Quantidade de blocos","maxLength":100,"required":true,"type":"number","value":_vm.level.blocks_amount}}),_vm._v(" "),_c('app-textarea',{attrs:{"name":"instruction","label":"Instrução","placeholder":"Instrução","maxLength":255,"required":true,"counter":true,"resize":"none","value":_vm.level.instruction}}),_vm._v(" "),_c('app-textarea',{attrs:{"name":"blocks","label":"Blocos","placeholder":"Blocos","maxLength":2000,"required":true,"counter":true,"resize":"none","value":_vm.level.blocks}}),_vm._v(" "),_c('app-textarea',{attrs:{"name":"map","label":"Mapa","placeholder":"Mapa","maxLength":255,"required":true,"counter":true,"resize":"none","value":_vm.level.map}}),_vm._v(" "),_c('app-toggle',{attrs:{"label":"Status","name":"active","value":_vm.level.active}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{class:[
                                                'card-header d-flex justify-content-between',
                                                'align-items-center px-2'
                                            ]},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Dicas")]),_vm._v(" "),_c('div',[_c('a',{class:[
                                                        'btn btn-danger btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ],attrs:{"title":"Excluir Dica"},on:{"click":_vm.onDelete}},[_c('span',{staticClass:"fa fa-trash text-white"})]),_vm._v(" "),_c('a',{class:[
                                                        'btn btn-info btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ],attrs:{"title":"Add Dica"},on:{"click":_vm.onAdd}},[_c('span',{staticClass:"fa fa-plus text-white"})])])]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},_vm._l((_vm.tips),function(tip,index){return _c('div',{staticClass:"col-12 col-xl-6"},[(tip.id)?_c('input',{attrs:{"name":'tips[' + index + '][id]',"type":"hidden"},domProps:{"value":tip.id}}):_vm._e(),_vm._v(" "),_c('app-textarea',{attrs:{"containerClass":"","name":'tips[' + index + '][text]',"label":'Dica ' + index,"placeholder":"Dica","maxLength":300,"required":true,"counter":true,"resize":"none","value":tip.text}})],1)}),0),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":"tipIDs"},domProps:{"value":_vm.tipRemovalList}})])])])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('app-button',{attrs:{"type":"update","callback":_vm.onSubmit}}),_vm._v(" "),_c('app-link',{attrs:{"type":"back","url":_vm.backUrl}})],1)])])])])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }