<template>
    <div>
        <app-page-header
            icon="fa fa-user"
            title="Alunos"
            :breadcrumbs="[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Alunos' }
            ]"
        ></app-page-header>

        <app-datatable
            ref="dataTable"
            entity="players"
            :allow-enable-multi="allows.enableMulti"
            :allow-disable-multi="allows.disableMulti"
            :allow-delete-multi="allows.deleteMulti"
            :options="{
                ajax: {
                    url: '/web/players'
                },
                columns: columns,
                searchCols: initFilter,
            }"
            :actions="{
                show: {
                    allow: allows.show,
                    url: '*',
                    callback: onShow
                },
                details: {
                    allow: allows.details,
                    url: '*',
                    callback: onDetails
                },
            }"
            :filters="filters"
        ></app-datatable>

        <app-player-show ref="playerShow"></app-player-show>
	    <app-player-detail ref="playerDetail"></app-player-detail>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
	import DataTable from '../DataTableComponent';
    import PlayerShow from './PlayerShowComponent';
    import PlayerDetail from './PlayerDetailComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
			'app-datatable': DataTable,
            'app-player-show': PlayerShow,
            'app-player-detail': PlayerDetail,
        },
        data() {
            return {
                filters: [
                    {
                        name: 'school_id',
                        type: 'select',
                        label: 'Escola',
                        index: 6,
                        options: [],
                        liveSearch: true,
                        callback: this.loadClassrooms,
                    },
                    {
                        name: 'classroom_id',
                        type: 'select',
                        label: 'Sala',
                        index: 7,
                        options: [],
                        liveSearch: true,
                    }
                ],
                initFilter: [],
            }
        },
        props: {
            allows: Object,
            columns: Array,
            params: Object | Array,
        },
        mounted() {
            this.loadSchools();
            this.loadInitFilter();
        },
        methods: {
            loadSchools() {
                axios.get('/web/schools/list?toTags=true')
                    .then(res => {
                        let filter = this.filters.filter(f => {
                            return f.name == 'school_id';
                        })[0];
                        filter.options = res.data;

                        if (res.data.length == 1) {
                            filter.value = res.data[0].value;
                            this.loadClassrooms(res.data[0], true);
                        }
                    })
                    .catch(err => {});
            },
            loadClassrooms(e, starting = false) {
                axios.get(`/web/classrooms/findBySchool/${e.value}?toTags=true`)
                    .then(res => {
                        if (starting) {
                            let filter = this.filters.filter(f => {
                                return f.name == 'classroom_id';
                            })[0];
                            filter.options = res.data;
                        } else {
                            this.$refs.dataTable.setNewOptions('classroom_id', res.data);
                        }
                    })
                    .catch(err => {});
            },
            onShow(id) {
                this.$refs.playerShow.open(id);
            },
            onDetails(id) {
                this.$refs.playerDetail.open(id);
            },
            loadInitFilter() {
                loop1:
                for (let i = 0; i < this.columns.length; i++) {
                    let col = this.columns[i].name;
                loop2:
                    for (let param of Object.keys(this.params)) {
                        if (param == 'id') continue;

                        if (String(col).indexOf(param) != -1) {
                            this.initFilter.push({ search: this.params[param] });
                            continue loop1;
                        }
                    }

                    this.initFilter.push(null);
                }
            },
        }
    }
</script>