var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-globe","title":"Cadastrar Mundo","breadcrumbs":[
				{ href: '/', title: 'Painel' },
				{ href: '/worlds', title: 'Mundos' },
				{ href: '', title: 'Cadastrar' } ]}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tite-body"},[_c('form',{ref:"form"},[_c('div',{staticClass:"row"},[_c('app-input',{attrs:{"title":"mínimo 2 caracters","name":"name","label":"Nome","placeholder":"Nome","maxLength":150,"minLength":2,"required":true}}),_vm._v(" "),_c('app-input',{attrs:{"title":"mínimo 2 caracters","name":"subtitle","label":"Descrição","placeholder":"Descrição","maxLength":150,"minLength":2,"required":true}})],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('div',{staticClass:"card"},[_c('div',{class:[
                                                'card-header d-flex justify-content-between',
                                                'align-items-center px-2'
                                            ]},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Tutorial")]),_vm._v(" "),_c('div',[_c('a',{class:[
                                                        'btn btn-danger btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ],attrs:{"title":"Excluir Tutorial"},on:{"click":_vm.onDelete}},[_c('span',{staticClass:"fa fa-trash text-white"})]),_vm._v(" "),_c('a',{class:[
                                                        'btn btn-info btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ],attrs:{"title":"Add Tutorial"},on:{"click":_vm.onAdd}},[_c('span',{staticClass:"fa fa-plus text-white"})])])]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},_vm._l((_vm.tutorials),function(tutorial,index){return _c('app-textarea',{key:index,attrs:{"containerClass":"col-12 col-xl-6","name":'tutorials[' + index + '][text]',"label":'Tutorial ' + index,"placeholder":"Tutorial","maxLength":300,"required":true,"counter":true,"resize":"none","value":tutorial.text}})}),1)])])])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('app-button',{attrs:{"callback":_vm.onSubmit}}),_vm._v(" "),_c('app-link',{attrs:{"type":"back","url":_vm.backUrl}})],1)])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }