<template>
    <div v-if="exercise">
        <app-page-header
			icon="fa fa-check-circle-o"
			:title="'Editar Exercício: ' + id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/exercises', title: 'Exercícios' },
				{ href: '', title: 'Editar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
						<form ref="form">
                            <div class="row">
                                <app-select
									name="skill_id"
									label="Habilidade"
									title="Habilidade"
									:required="true"
									:options="skills"
									:data-live-search="true"
									empty="false"
                                    :value="dataTemp.skill_id"
								></app-select>
                                <app-input
                                    title="mínimo 2 caracters, máximo 20"
                                    name="title"
                                    label="Título"
                                    placeholder="Título"
                                    :maxLength="20"
                                    :minLength="2"
                                    :required="true"
                                    :value="exercise.title"
                                ></app-input>
                                <app-toggle
									label="Status"
									name="active"
									:value="exercise.active"
								></app-toggle>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Select from '../forms/SelectComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import Toggle from '../forms/ToggleComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-select': Select,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
            'app-toggle': Toggle,
        },
        data() {
            return {
                skills: [],
                exercise: null,
                dataTemp: {
                    skill_id: '',
                }
            }
        },
        props: {
            id: String,
            backUrl: String,
        },
        mounted() {
            this.load(this.id);
        },
        methods: {
            load(id) {
                axios.get(`/web/exercises/${id}`)
                    .then(res => {
                        this.exercise = res.data;
                        this.loadSkills();
                    })
                    .catch(err => {});
            },
            loadSkills() {
                axios.get('/web/skills/list?toTags=true')
                    .then(res => {
                        this.skills = res.data;
                        this.dataTemp.skill_id = this.exercise.skills[0].skill_id;
                    })
                    .catch(err => {});
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');

                    axios.post(`/web/exercises/${this.exercise.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Atualizado com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/exercises';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                        });
                }
            }
        }
    }
</script>
