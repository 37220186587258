<template>
    <div v-if="world">
        <app-page-header
			icon="fa fa-globe"
            :title="'Editar Mundo:  ' + id"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/worlds', title: 'Mundos' },
				{ href: '', title: 'Editar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
						<form ref="form">
                            <div class="row">
                                <app-input
                                    title="mínimo 2 caracters"
                                    name="name"
                                    label="Nome"
                                    placeholder="Nome"
                                    :maxLength="150"
                                    :minLength="2"
                                    :required="true"
                                    :value="world.name"
                                ></app-input>

                                <app-input
                                    title="mínimo 2 caracters"
                                    name="subtitle"
                                    label="Descrição"
                                    placeholder="Descrição"
                                    :maxLength="150"
                                    :minLength="2"
                                    :required="true"
                                    :value="world.subtitle"
                                ></app-input>

                                <app-toggle
									label="Status"
									name="active"
									:value="world.active"
								></app-toggle>
                            </div>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="card">
                                        <div
                                            :class="[
                                                'card-header d-flex justify-content-between',
                                                'align-items-center px-2'
                                            ]"
                                        >
                                            <div class="font-weight-bold">Tutorial</div>
                                            <div>
                                                <a
                                                    :class="[
                                                        'btn btn-danger btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ]"
                                                    @click="onDelete"
                                                    title="Excluir Tutorial"
                                                >
                                                    <span class="fa fa-trash text-white"></span>
                                                </a>
                                                <a
                                                    :class="[
                                                        'btn btn-info btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ]"
                                                    @click="onAdd"
                                                    title="Add Tutorial"
                                                >
                                                    <span class="fa fa-plus text-white"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div
                                                    v-for="(tutorial, index) in tutorials"
                                                    class="col-12 col-xl-6"
                                                >
                                                    <input
                                                        v-if="tutorial.id"
                                                        :name="'tutorials[' + index + '][id]'"
                                                        type="hidden"
                                                        :value="tutorial.id"
                                                    >
                                                    <app-textarea
                                                        containerClass=""
                                                        :name="'tutorials[' + index + '][text]'"
                                                        :label="'Tutorial ' + index"
                                                        placeholder="Tutorial"
                                                        :maxLength="300"
                                                        :required="true"
                                                        :counter="true"
                                                        resize="none"
                                                        :value="tutorial.text"
                                                    ></app-textarea>
                                                </div>
                                                <input
                                                    type="hidden"
                                                    name="tutorialIDs"
                                                    :value="tutorialRemovalList"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button type="update" :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Textarea from '../forms/TextareaComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';
    import Toggle from '../forms/ToggleComponent';

    export default {
        components: {
            'app-page-header': PageHeader,
            'app-textarea': Textarea,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
            'app-toggle': Toggle,
        },
        data() {
            return {
                world: null,
                tutorials: [],
                tutorialRemovalList: '',
            }
        },
        props: {
            id: String,
            backUrl: String,
        },
        mounted() {
            this.load(this.id);
        },
        methods: {
            load(id) {
                axios.get(`/web/worlds/${id}`)
                    .then(res => {
                        this.world = res.data;
                        this.tutorials = this.world.tutorials;
                    })
                    .catch(err => {});
            },
            onAdd() {
                this.tutorials.push({ text: '' });
            },
            onDelete() {
                if (this.tutorials.length) {
                    let id = this.tutorials[this.tutorials.length - 1].id;
                    this.tutorialRemovalList = this.tutorialRemovalList + ',' + id;
                }

                this.tutorials.pop();
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    formData.append('_method', 'PATCH');

                    axios.post(`/web/worlds/${this.world.id}`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Atualizado com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/worlds';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar atualizar registro!');
                        });
                }
            }
        }
    }
</script>