<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
		<div class="modal-dialog modal-dialog-centered modal-lg with-thumb" role="document">
			<div class="modal-content">
				<div class="modal-header border-0">
					<img
						v-if="!loading"
						:src="player.avatar.replace('*', '120')"
						class="thumb thumb-sm img-thumbnail rounded img-fluid thumb-show shadow"
					>
					<h5 class="modal-title text-center w-100">
						<i class="fa fa-user"></i>
						{{ title }}
					</h5>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						@click="onClose"
					>
			         	<span aria-hidden="true">&times;</span>
			        </button>
				</div>
				<div class="modal-body">
					<div class="row content">
						<div v-if="loading" class="col-12 text-center">
							<img src="/assets/img/loading.svg">
						</div>
						<div v-if="!loading" class="col-12">
							<div class="row">
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>ID</strong></p>
									<p>{{ player.id }}</p>
								</div>
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>Nome</strong></p>
									<p>{{ player.name }}</p>
								</div>
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>Usuário</strong></p>
									<p>{{ player.user.username }}</p>
								</div>
								<div class="col-12 col-xl-4 col-md-6">
									<p><strong>Primeiro acesso</strong></p>
									<p>{{ new Date(player.created_at).toLocaleDateString() }}</p>
								</div>
                                <div class="col-12 col-xl-4 col-md-6">
									<p><strong>Último acesso</strong></p>
									<p>
                                        {{
                                            (player.lastAccess)
                                            ? new Date(player.lastAccess).toLocaleDateString()
                                            : '-'
                                        }}
                                    </p>
								</div>
								<div class="col-12 mb-3">
									<p>
                                        <strong>Escola</strong>
                                    </p>
                                    <ul class="list-group">
										<p v-if="!player.school" class="alert alert-danger">
                                            Aluno sem escola
                                        </p>
										<a
											v-if="player.school"
											:class="[
												'list-group-item btn btn-outline-info',
												'd-flex justify-content-between'
											]"
										>
											{{ player.school.name }}
										</a>
                                    </ul>
								</div>
								<div class="col-12">
                                    <p>
                                        <strong>Sala</strong>
                                    </p>
                                    <ul class="list-group">
										<p
											v-if="!player.classroom"
											class="alert alert-danger"
										>
                                            Aluno sem sala
                                        </p>
										<a
											v-if="player.classroom"
											:class="[
												'list-group-item btn btn-outline-info',
												'd-flex justify-content-between'
											]"
										>
											{{ player.classroom.title }}
										</a>
                                    </ul>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        data() {
			return {
				modal: HTMLElement,
				title: '',
				loading: true,
				player: {}
			}
		},
		props: {
			defaultTitle: { type: String, default: 'Aluno' }
		},
		mounted() {
			this.title = this.defaultTitle;
		},
		methods: {
			open(id) {
				this.loading = true;
				this.modal = $(this.$refs.modal);
				this.title = 'Carregando...';
				this.modal.modal('show');

				axios.get(`/web/players/${id}`)
					.then(res => {
						this.loading = false;
						this.player = res.data;
						this.title = `${this.defaultTitle}`;
					})
					.catch(err => {});
			},
			onClose() {
				this.modal.modal('hide');
			}
		}
    }
</script>