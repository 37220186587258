<template>
    <div ref="qrStage" id="qr-stage" class="qr-stage" :class="{ 'on' : isOpened }">
        <a class="bt-close on" href="javascript:">
            <img src="/assets/img/iconClose.png">
        </a>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                qrStage: HTMLElement,
                btClose: HTMLElement,
                quiz: null,
                isOpened: false,
                id: null,
            }
        },
        props: {
            URL: { type: String, default: '/storage/files/quiz_reader/reader.js' }
        },
        mounted() {
            this.qrStage = this.$refs.qrStage;
            this.btClose = this.qrStage.querySelector('.bt-close');
        },
        methods: {
            load(id) {
                console.clear();
                Preloader.show();

                axios.get(`/web/exercises/${id}`)
                    .then(res => {
                        this.id = res.data.id;
                        this.loadReader();
                    })
                    .catch(err => {});
            },
            loadReader() {
                this.addScripts([this.URL], this.onReady, this.onLoadError);
            },
            async onReady() {
                Preloader.hide();
                this.isOpened = true;
                $(this.btClose).on('click', this.destroy);

                this.quiz = new QuizReader.Main({
                    quizId: this.id,
                    quizType: 'exercise',
                    baseUrl: '/api',
                    filesUrl: '/storage/files/quiz_reader',
                    screenFinal: this.destroy,
                    preview: true,
                    token: TOKEN,
                    student: {
                        data: {
                            year: { id: 1, title: '1' }
                        }
                    }
                });

                await Help.delay(100);

                this.setBtPosition();
            },
            setBtPosition() {
                var position = { y: 5, x: window.innerWidth - 45 };

				if ($(window).width() > 1004 && $(window).height() > 580) {
					let bounds = $('#quizReader')[0].getBoundingClientRect();
					position = { x: bounds.x, y: bounds.y };

					position.y = position.y - 22;
					position.x = position.x + bounds.width - 22;
				}

				$(this.btClose).css({
					top: position.y,
					left: position.x
				});
            },
            onLoadError(e) {
                Preloader.hide();
                Swal.fire({
                    title: 'Ops, visualização indiponível!',
                    text: 'Arquivo não encontrado!',
                    type: 'warning',
                });
            },
            destroy() {
                if (this.quiz) { this.quiz.destroy(); }

                this.quiz = null;

                document.getElementById('quizReader').remove();
                console.log($(`script[src="${this.URL}"]`));
                $(this.btClose).off();
                this.isOpened = false;
                console.clear();
            }
        }
    }
</script>
