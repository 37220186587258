<template>
    <div>
        <app-page-header
            icon="fa fa-dashboard"
            title="Painel"
            :breadcrumbs="[
                { href: '', title: 'Painel' },
            ]"
        ></app-page-header>

        <div class="row">
            <app-jumbotron :name="userName"></app-jumbotron>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Jumbotron from '../JumbotronComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-jumbotron': Jumbotron,
		},
        data() {
            return {
                userName: '',
            }
        },
        props: {
            user: Object,
        },
        mounted() {
            this.userName = this.getName();
        },
        methods: {
            getName() {
                switch (this.user.profile.code) {
                    case 'manager': return this.user.manager.name;
                    case 'teacher': return this.user.teacher.name;
                }

                return '';
            }
        }
    }
</script>