var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-globe","title":"Mundos","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Mundos' } ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"worlds","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"statusFilterIndex":5,"options":{
            ajax: {
                url: '/web/worlds',
            },
            columns: _vm.columns,
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/worlds/create'
            },
            show: {
                allow: _vm.allows.show,
                url: '*',
                callback: _vm.onShow
            },
            level: {
                allow: _vm.allows.level,
                url: '/levels/world_id=*',
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/worlds/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*'
            },
        }}}),_vm._v(" "),_c('app-world-show',{ref:"worldShow"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }