var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modal",staticClass:"modal fade",attrs:{"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg with-thumb",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header border-0"},[(!_vm.loading)?_c('img',{staticClass:"thumb thumb-sm img-thumbnail rounded img-fluid thumb-show shadow",attrs:{"src":_vm.player.avatar.replace('*', '120')}}):_vm._e(),_vm._v(" "),_c('h5',{staticClass:"modal-title text-center w-100"},[_c('i',{staticClass:"fa fa-user"}),_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.title)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.onClose}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row content"},[(_vm.loading)?_c('div',{staticClass:"col-12 text-center"},[_c('img',{attrs:{"src":"/assets/img/loading.svg"}})]):_vm._e(),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-4 col-md-6"},[_vm._m(0),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.player.id))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-4 col-md-6"},[_vm._m(1),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.player.name))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-4 col-md-6"},[_vm._m(2),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.player.user.username))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-4 col-md-6"},[_vm._m(3),_vm._v(" "),_c('p',[_vm._v(_vm._s(new Date(_vm.player.created_at).toLocaleDateString()))])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-xl-4 col-md-6"},[_vm._m(4),_vm._v(" "),_c('p',[_vm._v("\n                                        "+_vm._s((_vm.player.lastAccess)
                                            ? new Date(_vm.player.lastAccess).toLocaleDateString()
                                            : '-')+"\n                                    ")])]),_vm._v(" "),_c('div',{staticClass:"col-12 mb-3"},[_vm._m(5),_vm._v(" "),_c('ul',{staticClass:"list-group"},[(!_vm.player.school)?_c('p',{staticClass:"alert alert-danger"},[_vm._v("\n                                            Aluno sem escola\n                                        ")]):_vm._e(),_vm._v(" "),(_vm.player.school)?_c('a',{class:[
												'list-group-item btn btn-outline-info',
												'd-flex justify-content-between'
											]},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.player.school.name)+"\n\t\t\t\t\t\t\t\t\t\t")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_vm._m(6),_vm._v(" "),_c('ul',{staticClass:"list-group"},[(!_vm.player.classroom)?_c('p',{staticClass:"alert alert-danger"},[_vm._v("\n                                            Aluno sem sala\n                                        ")]):_vm._e(),_vm._v(" "),(_vm.player.classroom)?_c('a',{class:[
												'list-group-item btn btn-outline-info',
												'd-flex justify-content-between'
											]},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.player.classroom.title)+"\n\t\t\t\t\t\t\t\t\t\t")]):_vm._e()])])])]):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("ID")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Nome")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Usuário")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Primeiro acesso")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Último acesso")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Escola")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Sala")])])}]

export { render, staticRenderFns }