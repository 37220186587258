<template>
    <div ref="modalDetail" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <i class="fa fa-line-chart"></i>
                        {{ title }}
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="onClose"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <fieldset>
                        <legend>
                            <a
                                class="btn d-flex justify-content-between align-items-center"
                                href="javascript:"
                                data-toggle="collapse"
                                data-target="#collapseLevels"
                                @click="onToggle"
                            >
                                <h5 class="mr-3">Fases</h5>
                                <i class="fa fa-angle-down"></i>
                            </a>
                        </legend>
                        <div class="collapse show" id="collapseLevels">
                            <div class="row content">
                                <app-select
                                    name="world_id"
                                    label="Mundo"
                                    title="Mundo"
                                    :options="worlds"
                                    empty="false"
                                    :value="worldId"
                                    :callback="onWorldSelected"
                                ></app-select>
                            </div>
                            <div v-if="loading" class="row">
                                <div class="col-12 text-center">
                                    <img src="/assets/img/loading.svg">
                                </div>
                            </div>
                            <div class="row" v-if="!loading">
                                <div class="col-12 mb-3">
                                    <label>Desempenho</label>
                                    <div class="progress">
                                        <div
                                            :class="[
                                                'progress-bar bg-',
                                                report.performance_color,
                                                'progress-bar-striped'
                                            ]"
                                            :style="{ width: report.performance + '%' }"
                                            :aria-valuenow="report.performance"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            role="progressbar"
                                        >
                                            <i class="font-weight-bold">
                                                {{ report.performance }}%
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <app-level-card
                                    v-for="level of report.levels"
                                    :level="level"
                                    :key="level.id"
                                ></app-level-card>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>
                            <a
                                class="btn d-flex justify-content-between align-items-center"
                                href="javascript:"
                                data-toggle="collapse"
                                data-target="#collapseExercises"
                                @click="onToggle"
                            >
                                <h5 class="mr-3">Exercícios</h5>
                                <i class="fa fa-angle-down"></i>
                            </a>
                        </legend>
                        <div class="row collapse show" id="collapseExercises">
                            <app-exercise-card
                                v-for="exercise of exercises"
                                :exercise="exercise"
                                :key="exercise.id"
                            ></app-exercise-card>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Select from '../forms/SelectComponent';
    import LevelCard from './LevelCardComponent';
    import ExerciseCard from './ExerciseCardComponent';

    export default {
        components: {
            'app-select': Select,
            'app-level-card': LevelCard,
            'app-exercise-card': ExerciseCard,
        },
        data() {
            return {
                modal: HTMLElement,
                title: '',
                loading: true,
                worlds: [],
                playerId: Number,
                worldId: 1,
                report: null,
                exercises: [],
            }
        },
        props: {
            defaultTitle: { type: String, default: 'Relatório de Fases' }
        },
        mounted() {
            this.title = this.defaultTitle;
            this.loadWorlds();
        },
        methods: {
            onToggle(e) {
                let a = $(e.currentTarget);
                let arrow = a.find('i');
                
                arrow.toggleClass('fa fa-angle-up');
                arrow.toggleClass('fa fa-angle-down');
            },
            getProgressColor(value) {
                let color = 'danger';

                if (value >= 50 && value <=70) {
                    color = 'warning';
                } else if (value > 70) {
                    color = 'success';
                }

                return color;
            },
            onWorldSelected(worldId) {
                this.worldId = worldId;
                this.load();
            },
            loadWorlds() {
                axios.get('web/worlds/list?toTags=true')
                    .then(res => {
                        this.worlds = res.data;
                    })
                    .catch(err => {});
            },
            open(id) {
                this.worldId = 1;
                this.playerId = id;
				this.modal = $(this.$refs.modalDetail);
				
                this.load();
                this.loadExercises();
            },
            load() {
                this.loading = true;
                this.title = 'Carregando...';
				this.modal.modal('show');

                axios.get(`/report/players/${this.playerId}/details?world_id=${this.worldId}`)
					.then(res => {
						this.loading = false;
                        this.report = res.data;
						this.title = `${this.defaultTitle}: ${this.report.player.name}`;

                        this.report.performance_color =
                            this.getProgressColor(this.report.performance);
                        
                        for (let level of this.report.levels) {
                            level.color = this.getProgressColor((level.stars / 3) * 100);
                        }
					})
					.catch(err => {});
            },
            loadExercises() {
                axios.get(`/report/exercises/${this.playerId}`)
                    .then(res => {
                        this.exercises = res.data;
                    })
                    .catch(err => {});
            },
            onClose() {
				this.modal.modal('hide');
			}
        }
    }
</script>