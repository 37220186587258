var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-flag","title":"Fases","breadcrumbs":[
            { href: '/', title: 'Painel' },
            { href: '', title: 'Fases' } ]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"levels","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"statusFilterIndex":6,"options":{
            ajax: {
                url: '/web/levels',
            },
            columns: _vm.columns,
        },"actions":{
            create: {
                allow: _vm.allows.create,
                url: '/levels/create'
            },
            show: {
                allow: _vm.allows.show,
                url: '*',
                callback: _vm.onShow
            },
            edit: {
                allow: _vm.allows.edit,
                url: '/levels/*/edit',
            },
            delete: {
                allow: _vm.allows.delete,
                url: '*'
            },
        },"filters":_vm.filters}}),_vm._v(" "),_c('app-level-show',{ref:"levelShow"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }