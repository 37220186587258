<template>
    <div>
        <app-page-header
			icon="fa fa-flag"
			title="Cadastrar Fase"
			:breadcrumbs="[
				{ href: '/', title: 'Painel' },
				{ href: '/levels', title: 'Fases' },
				{ href: '', title: 'Cadastrar' },
			]"
		></app-page-header>

        <div class="row">
			<div class="col-12">
				<div class="tile">
					<div class="tite-body">
						<form ref="form">
                            <div class="row">
                                <app-select
									name="world_id"
									label="Mundo"
									title="Mundo"
									:required="true"
									:options="worlds"
									:data-live-search="true"
									empty="false"
								></app-select>
                                <app-input
                                    title="mínimo 2 caracters, máximo 100"
                                    name="name"
                                    label="Nome"
                                    placeholder="Nome"
                                    :maxLength="100"
                                    :minLength="2"
                                    :required="true"
                                ></app-input>
                                <app-select
                                    containerClass="col-12 col-xl-2 col-md-6"
									name="direction"
									label="Direção Inicial"
									title="Direção Inicial"
									:required="true"
									:options="directions"
									:data-live-search="true"
									empty="false"
								></app-select>
                                <app-input
                                    containerClass="col-12 col-xl-2 col-md-6"
                                    title="mínimo 2 caracters, máximo 100"
                                    name="blocks_amount"
                                    label="Quantidade de blocos"
                                    :maxLength="100"
                                    :required="true"
                                    type="number"
                                ></app-input>
                                <app-textarea
                                    name="instruction"
                                    label="Instrução"
                                    placeholder="Instrução"
                                    :maxLength="255"
                                    :required="true"
                                    :counter="true"
                                    resize="none"
                                ></app-textarea>
                                <app-textarea
                                    name="blocks"
                                    label="Blocos"
                                    placeholder="Blocos"
                                    :maxLength="2000"
                                    :required="true"
                                    :counter="true"
                                    resize="none"
                                ></app-textarea>
                                <app-textarea
                                    name="map"
                                    label="Mapa"
                                    placeholder="Mapa"
                                    :maxLength="255"
                                    :required="true"
                                    :counter="true"
                                    resize="none"
                                ></app-textarea>
                            </div>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="card">
                                        <div
                                            :class="[
                                                'card-header d-flex justify-content-between',
                                                'align-items-center px-2'
                                            ]"
                                        >
                                            <div class="font-weight-bold">Dicas</div>
                                            <div>
                                                <a
                                                    :class="[
                                                        'btn btn-danger btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ]"
                                                    @click="onDelete"
                                                    title="Excluir Dica"
                                                >
                                                    <span class="fa fa-trash text-white"></span>
                                                </a>
                                                <a
                                                    :class="[
                                                        'btn btn-info btn-circle',
                                                        'btn-circle-sm btn-table'
                                                    ]"
                                                    @click="onAdd"
                                                    title="Add Dica"
                                                >
                                                    <span class="fa fa-plus text-white"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <app-textarea
                                                    v-for="(tip, index) in tips"
                                                    :key="index"
                                                    containerClass="col-12 col-xl-6"
                                                    :name="'tips[' + index + '][text]'"
                                                    :label="'Dica ' + index"
                                                    placeholder="Dica"
                                                    :maxLength="300"
                                                    :required="true"
                                                    :counter="true"
                                                    resize="none"
                                                    :value="tip.text"
                                                ></app-textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-button :callback="onSubmit"></app-button>
                                    <app-link type="back" :url="backUrl"></app-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '../PageHeaderComponent';
    import Select from '../forms/SelectComponent';
    import Textarea from '../forms/TextareaComponent';
    import Input from '../forms/InputComponent';
    import Button from '../forms/ButtonComponent';
    import Link from '../forms/LinkComponent';

    export default {
        components: {
			'app-page-header': PageHeader,
            'app-select': Select,
            'app-textarea': Textarea,
            'app-input': Input,
            'app-button': Button,
            'app-link': Link,
        },
        data() {
            return {
                tips: [],
                worlds: [],
                directions: [],
            }
        },
        props: {
            backUrl: String,
        },
        mounted() {
            this.loadWorlds();
            this.loadDirections();
        },
        methods: {
            loadWorlds() {
                axios.get('/web/worlds/list?toTags=true')
                    .then(res => {
                        this.worlds = res.data;
                    })
                    .catch(err => {});
            },
            loadDirections() {
                axios.get('/web/levels/directions?toTags=true')
                    .then(res => {
                        this.directions = res.data;
                    })
                    .catch(err => {});
            },
            onAdd() {
                this.tips.push({ text: '' });
            },
            onDelete() {
                this.tips.pop();
            },
            onSubmit() {
                let formData = new FormData(this.$refs.form);
                let validate = this.validateForm(this.$refs.form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    axios.post(`/web/levels`, formData)
                        .then(res => {
                            Swal.fire({
                                title: 'Salvo com sucesso!',
                                type: 'success'
                            })
                            .then(() => {
                                window.location.href = '/levels';
                            });
                        })
                        .catch(err => {
                            Flash('error', 'Erro ao tentar gravar registro!');
                        });
                }
            }
        }
    }
</script>