var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-user","title":"Alunos","breadcrumbs":[
                { href: '/', title: 'Painel' },
                { href: '', title: 'Alunos' }
            ]}}),_vm._v(" "),_c('app-datatable',{ref:"dataTable",attrs:{"entity":"players","allow-enable-multi":_vm.allows.enableMulti,"allow-disable-multi":_vm.allows.disableMulti,"allow-delete-multi":_vm.allows.deleteMulti,"options":{
                ajax: {
                    url: '/web/players'
                },
                columns: _vm.columns,
                searchCols: _vm.initFilter,
            },"actions":{
                show: {
                    allow: _vm.allows.show,
                    url: '*',
                    callback: _vm.onShow
                },
                details: {
                    allow: _vm.allows.details,
                    url: '*',
                    callback: _vm.onDetails
                },
            },"filters":_vm.filters}}),_vm._v(" "),_c('app-player-show',{ref:"playerShow"}),_vm._v(" "),_c('app-player-detail',{ref:"playerDetail"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }